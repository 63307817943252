@import "MuseoModerno.css";
@import "Jost.css";

@font-face {
    font-family: 'eco-font';
    src: url('fonts/eco-font.eot?yekm01');
    src: url('fonts/eco-font.eot?yekm01#iefix') format('embedded-opentype'),
        url('fonts/eco-font.ttf?yekm01') format('truetype'),
        url('fonts/eco-font.woff?yekm01') format('woff'),
        url('fonts/eco-font.svg?yekm01#eco-font') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

:root {
    --bs-body-font-family: 'Jost';
}

body {
    /* background-color: var(--bs-light); */
}

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .nav-link {}

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}

.btn-bd-primary {
    background: var(--bs-secondary);
    border-radius: 50%;
    transition: all .3s;
}

.modal-price-details .modal-header .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.product-specifications-accordion.accordion .accordion-item .accordion-body a {
    color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.product-specifications-accordion.accordion .accordion-item .accordion-body a:hover {
    text-decoration: none;
}

.w-50px {
    width: 50px;
}

.w-100px {
    width: 100px;
}

.mw-125px {
    min-width: 125px;
}

.w-90 {
    width: 90% !important;
}

.h-50px {
    height: 50px;
}

.h-75px {
    height: 75px;
}

.ego-swiper .swiper-slide {
    height: auto;
    padding: 0 0.5rem;
}

.ego-swiper .swiper-slide .slider-content {
    display: table;
}

.ego-swiper .swiper-slide .slider-content .slider-header {
    display: table-row;
}

.ego-swiper .swiper-slide .slider-content .slider-body {
    display: table-row;
    height: 100%;
}

.ego-swiper .swiper-slide .slider-content .slider-body .card {
    height: 100%;
    width: 100%;
    display: table;
}

div[class*="swiper-custom-pagination"] {
    margin-top: 3rem;
    display: flex;
    justify-content: center !important;
}

div[class*="swiper-custom-pagination"] .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    display: table;
    border: 0;
    margin: 0 1rem !important;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

div[class*="swiper-custom-pagination"] .swiper-pagination-bullet.swiper-pagination-bullet-active {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.tooltip-component {
    display: inline-table;
    vertical-align: top;
}

.tooltip-component button {
    background-color: #777d74;
    border: 0;
    font-size: 10px;
    color: #fff;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 15px;
    margin-left: 5px;
    vertical-align: top;
    display: table-cell;
    font-style: italic;
    font-weight: bold;
}

.icon-container {
    font-family: eco-font !important;
}

.icon-lampe-2:before {
    content: "\e904";
}

.object-fit-cover {
    object-fit: cover;
}

.color-gray-500 {
    color: var(--bs-gray-500);
}

.logo-img-fixed-wh {
    height: 50px;
    width: 50px;
}

.zIndex-1 {
    z-index: 1;
}

.persons-count {
    padding: 0.15rem 0.25rem;
}

.person-count .btn-group>label:last-child {
    margin-right: 0 !important;
}

.vendor-logo {
    width: auto;
    height: 2.5rem;
}

@media all and (min-width: 768px) and (max-width: 992px) {
    .vendor-logo {
        /*width: auto;
        height: 1.7rem;*/
        width: 100%;
        height: auto;
    }
}

@media all and (max-width: 768px) {
    .vendor-logo {
        /*width: auto;
        height: 2rem;*/
        width: 100%;
        height: auto;
    }
}

hr {
    margin: 2rem 0;
}

.card-discount-label {
    position: absolute;
    right: -5px;
    top: 15px;
    padding: 0.1rem 1rem;
    -webkit-box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.6);
}

.card-mobile-discount-label {
    position: absolute;
    left: -10px;
    top: 15px;
    padding: 0.1rem 1rem;
    -webkit-box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.6);
}

.large-card-discount-label {
    position: absolute;
    left: -5px;
    top: 20px;
    padding: 0.1rem 1rem;
    -webkit-box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -4px 4px 4px 0px rgba(34, 60, 80, 0.6);
}

.select-region-warning-label {
    position: absolute;
    right: -5px;
    width: auto;
    bottom: 70px;
    text-align: right;
    padding: 0.1rem 1rem;
    cursor: pointer;
    border: 0;
    -webkit-box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 4px 4px 0px rgba(34, 60, 80, 0.6);
}

/* style for header navbar */
.navbar-header {
    border-bottom: 1px solid transparent;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    z-index: 1025;
}

/* style for hero card dividers */
.hero-card-divider {

    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);


    /* border-top: 1px solid var(--bs-gray-300);
    border-bottom: 1px solid var(--bs-gray-300); */
}

.card-with-hover:hover {
    background: rgba(var(--bs-light-rgb))
}

.card .add-to-favorite {
    opacity: 0.5;
    transition: all 0.75s;
}

.card .add-to-favorite:before {
    content: "";
    display: table;
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    bottom: -4px;
}

@media (max-width: 992px) {
    .card .add-to-favorite {
        opacity: 1;
    }
}

.card:hover .add-to-favorite {
    opacity: 1;
}

@media all and (max-width: 992px) {
    [aria-labelledby="collasible-nav-dropdown"].dropdown-menu {
        border: 0;
        background: transparent;
    }
}

.header-nav-menu .nav-link:focus-visible {
    box-shadow: none;
}

.max-width-100px {
    max-width: 100px;
}

.max-width-50px {
    max-width: 50px;
}

.max-height-200px {
    max-height: 200px;
}

.image-logo-dummy-big {
    height: 36px;
    object-fit: scale-down;
}

.image-logo-regular {
    height: 32px;
    object-fit: scale-down;
}

.main-logo {
    float: left;
    display: table;
    margin-right: 0.5rem;
}

.main-logo svg {
    width: 40px;
    height: 40px;
}

@media all and (min-width: 768px) and (max-width: 992px) {
    .main-logo svg {
        width: 30px;
        height: 30px;
    }
}

@media all and (max-width: 768px) {
    .main-logo svg {
        width: 25px;
        height: 25px;
    }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
    .main-logo svg {
        width: 30px;
        height: 30px;
    }

    .logo-title {
        font-size: 2rem;
    }
}

@media (max-width: 992px) {
    .image-logo-regular {
        height: 24px;
        object-fit: scale-down;
    }
}

.modal-header,
.modal-body {
    padding: 1rem 1.5rem;
}

@media (max-width: 992px) {

    .modal-header,
    .modal-body {
        padding: 1rem;
    }
}

.image-fixed-size-portrait-300px {
    width: 300px;
    height: 225px;
    /* 3:4, Height = (Width * 3) / 4 = (300 * 3) / 4 = 225 pixels    */
}


.image-fixed-size-wide-300px {
    width: 300px;
    height: 533px;
    /* 16:9, Height = (Width * 9) / 16 = (300 * 9) / 16 = 533 pixels    */
}

.image-fixed-height-wide-h60px {
    width: 106px;
    /* 16:9, Width = (Height * 16) / 9 = (60 * 16) / 9 = 106 pixels    */
    height: 60px;
}

.image-fixed-height-wide-h50px {
    width: 89px;
    /* 16:9, Width = (Height * 16) / 9 = (60 * 16) / 9 = 106 pixels    */
    height: 50px;
}

.image-object-fit-scale-down {
    object-fit: scale-down;
}

.image-object-fit-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ego-tooltip {
    max-width: 100%;
}

.ego-tooltip .tooltip-inner {
    text-align: left;
}

.w-1rem {
    width: 1rem !important;
}

.w-2rem {
    width: 2rem !important;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.slider-background-color .rc-slider-track {
    background-color: var(--bs-primary);
}

.slider-background-color .rc-slider-handle {
    border-color: var(--bs-primary);
}

.slider-background-color .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--bs-warning) !important;
    box-shadow: 0 0 0px 5px var(--bs-secondary);

    background-color: var(--bs-primary);
}

.carousel-with-dots .carousel-indicators button {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    display: table;
    border: 0;
    margin: 0 1rem;
}

.carousel-with-dots.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.container-with-image {
    height: 400px;
}

.container-with-image .image-block {
    z-index: -1;
}

.container-with-image .image-block:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url("images/bg-transporent-grey.png") repeat;
}

.container-with-image .image-block-no-bg {
    z-index: -1;
}

.container-with-image .image-block-no-bg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.white-border {
    border: 2px solid rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.grey-border {
    border: 2px solid var(--bs-info);
}

.thin-grey-border {
    border: 1px solid var(--bs-info);
}

.bonus-slider-height {
    /* min-height: 31rem; */
    min-height: 400px;
}

.tech-details-name span {
    position: relative;
    padding-right: 10px;
    background: inherit;
    z-index: 1;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.tech-details-name:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 1.05rem;
    height: 1px;
    background: url("images/dot-bg.png") 0 0 repeat-x;
}

@media all and (max-width: 992px) {
    .bonus-slider-height {
        min-height: auto;
    }
}

.bonus-price-block .text-info {
    zoom: 0.5;
}

.big-checkbox.form-check {
    display: flex;
}

.big-checkbox.form-check .form-check-input {
    height: 3rem;
    width: 3rem;
    flex: none;
}

.big-checkbox.form-check .form-check-label {
    margin-top: 0.25rem;
    margin-left: 1rem;
}

.medium-checkbox.form-check {
    display: flex;
}

.medium-checkbox.form-check .form-check-input {
    height: 2rem;
    width: 2rem;
    flex: none;
}

.medium-checkbox.form-check .form-check-label {
    margin-top: 0.5rem;
    margin-left: 0.75rem;
}

.fit-image-to-col-by-content-height {
    position: absolute;
    top: 0;
    left: calc(var(--bs-gutter-x) * 0.5);
    width: calc(100% - var(--bs-gutter-x));
    height: 100%;
}

.variation-price-block {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px var(--bs-dark) inset;
    transition: all 0.3s !important;
    background: transparent;
    border: 0;
    position: relative;
}

.variation-price-block:hover {
    box-shadow: none;
}

.show-more-tablet-position {
    position: absolute;
    left: 0;
    bottom: 1rem;
}

.variation-price-block .card-body {
    padding: 0.5rem 0.7rem;
}

.variation-price-block .bg-block {
    position: absolute;
    transition: all 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--bs-card-border-radius);
    transform: translate(5px, 5px);
    z-index: -1;
}

.variation-price-block:hover .bg-block {
    transform: translate(-1px, -1px);
}

.form-control::placeholder {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.edit-buttons-group {
    position: absolute;
    z-index: 1;
    right: 0;
    width: 60px;
    padding: 0;
}

/* style for filter panel */
.filter-panel {
    position: sticky;
    top: 100px;
    /* adjust this value according to your header height */
    z-index: 99;
}

.sort-panel-sticky {
    position: sticky;
    top: 74px;
    z-index: 99;
    background-color: #fff;
}

.sort-panel {
    background-color: #fff;
}

@media all and (min-width: 576px) {

    .sort-panel,
    .sort-panel-sticky {
        top: 66px;
        padding: 0.5rem 0;
    }
}

@media all and (max-width: 576px) {

    .sort-panel,
    .sort-panel-sticky {
        top: 66px;
        padding: 0.5rem 0;
    }
}

/* input animation */
.input-glow {
    box-shadow: 0 0 0 0 var(--bs-danger);
    animation: input-glow-animation 1s ease-in-out;
}

@keyframes input-glow-animation {
    0% {
        box-shadow: 0 0 0 0 var(--bs-danger);
    }

    50% {
        box-shadow: 0 0 0 3px var(--bs-danger);
    }

    100% {
        box-shadow: 0 0 0 0 var(--bs-danger);
    }
}

.ego-text-two-columns {
    column-count: 2;
    column-width: 300px;
    columns: "2 300px";
}

.ego-flex-justify-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end
}

.wizard-card {
    background: transparent;
    border: 0;
}

.wizard-card .ego-text-subtitle {
    text-align: center;
    color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.wizard-card .alert-warning {
    background: transparent;
    border: 0;
    color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.wizard-card .form-check-input:checked {
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.wizard-card-large {
    z-index: 1;
}

.wizard-card .ego-text-subtitle {
    margin-bottom: 1rem !important;
}

.wizard-card .alert-warning {
    margin-bottom: 0;
    /*padding: 1rem 0 0 0;*/
}

.wizard-card-large .alert-warning {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -1rem;
    padding: 0;
}

@media all and (max-width: 992px) {
    .wizard-card-large .alert-warning {
        bottom: 0;
    }
}

.wizard-card .form-check-mobile {
    margin: 0.5rem 0;
}

.wizard-card-large .form-check input[type="radio"] {
    visibility: hidden;
    position: relative;
}

.isAvailable, .isNotAvailable {
    font-weight: bold;
    position: relative;
    padding-left: 1.25rem;
}

.isAvailable {
    color: rgba(var(--bs-success-rgb));
}

.isAvailable:before {
    content: "\e95f";
}

.isNotAvailable {
    color: rgba(var(--bs-danger-rgb));
}

.isNotAvailable:before {
    content: "\e961";
}

.isAvailable:before, .isNotAvailable:before {
    font-family: 'eco-font';
    font-weight: normal;
    position: absolute;
    left: 0;
}

ul.links-list li {
    list-style: none;
    display: inline-block;
    position: relative;
}

ul.links-list li:before {
    content: "–";
    position: absolute;
    right: -2px;
    color: rgba(var(--bs-primary-rgb));
}

ul.links-list li:last-child:before {
    content: "";
}

.bottom-minus-2 {
    bottom: -1rem !important;
}

ul.payment-cards {
    list-style: none;
    padding: 0;
    display: table;
    width: 100%;
    text-align: center;
}

ul.payment-cards li {
    display: inline-block;
}

ul.payment-cards li img {
    width: 75px;
    height: 48px;
    display: table;
    border-radius: 4px;
    margin: 0.25rem 1rem;
    border: 4px solid var(--bs-light);
}

/*.wizard-card-large .form-check input[type="radio"]:after {
    content: "e91f";
    font-family: 'eco-font' !important;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    display: table;
    position: absolute;
    visibility: visible;
    top: 15px;
    left: 81px;
    font-size: 5.5rem;
    z-index: -1;
}

.wizard-card-large .form-check input[type="radio"]:checked:after {
    color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.wizard-card-large .form-check input[value="owner"]:after,
.wizard-card-large .form-check input[value="owner_consent"]:after,
.wizard-card-large .form-check input[value="building_after1990"]:after,
.wizard-card-large .form-check input[value="repair_after1990"]:after,
.wizard-card-large .form-check input[value="on_house"]:after,
.wizard-card-large .form-check input[value="digging"]:after {
    content: "\e95d";
}

.wizard-card-large .form-check input[value="not_owner"]:after,
.wizard-card-large .form-check input[value="no_owner_consent"]:after,
.wizard-card-large .form-check input[value="no_building_after1990"]:after,
.wizard-card-large .form-check input[value="no_repair_after1990"]:after,
.wizard-card-large .form-check input[value="no_on_house"]:after,
.wizard-card-large .form-check input[value="no_digging"]:after {
    content: "\e960";
}

.wizard-card-large .form-check input[value="house"]:after {
    content: "\e958";
}

.wizard-card-large .form-check input[value="appartment"]:after {
    content: "\e959";
}

.wizard-card-large .form-check input[value="other_housing"]:after {
    content: "\e95c";
}

.wizard-card-large .form-check input[value="no_wallbreaks"]:after {
    content: "\e955";
}

.wizard-card-large .form-check input[value="up2_wallbreaks"]:after,
.wizard-card-large .form-check input[value="up3_wallbreaks"]:after,
.wizard-card-large .form-check input[value="more3_wallbreaks"]:after {
    content: "\e956";
}

.wizard-card-large .form-check input[value="no_conductor"]:after {
    content: "\e955";
}

.wizard-card-large .form-check input[value="up10_conductor"]:after,
.wizard-card-large .form-check input[value="up30_conductor"]:after {
    content: "\e964";
}

.wizard-card-large .form-check input[value="more30_conductor"]:after {
    content: "\e965";
}*/

.icon-with-circle {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-table;
    text-align: center;
    border-radius: 2rem;
    line-height: 1.3rem;
}

.order-icons-size {
    font-size: 7.0vw;
}

.wizard-card-large .form-check {
    width: 200px;
    height: 150px;
    color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
    background: transparent;
    position: relative;
    box-shadow: 0px 0px 0px 2px rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) inset;
    border-radius: var(--bs-card-border-radius);
    display: inline-table;
    padding: 0;
    margin: 0 1rem;
}

.wizard-card-large.w-mobile .form-check {
    width: 100%;
    height: 75px;
    display: table;
    margin: 1rem 0;
}

.wizard-card-large .form-check:hover {
    transition: all .5s;
    box-shadow: 0px 0px 0px 2px rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) inset;
    color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.wizard-card-large .form-check:before {
    content: "";
    border-radius: var(--bs-card-border-radius);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
    /*background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));*/
    background-color: transparent;
    transition: all .3s;
    width: 100%;
    z-index: -1;
}

.wizard-card-large .form-check:hover:before {
    transform: translate(0, 0);
}

.wizard-card-large .form-check label {
    display: table-cell;
    /*vertical-align: bottom;*/
    vertical-align: middle;
    font-size: 1.5rem;
    text-align: center;
    cursor: pointer;
    z-index: 10;
    position: relative;
    /*padding: 0 0.5rem 0.5rem 0.5rem;*/
    padding: 0 1rem;
}

.wizard-card-large.w-mobile .form-check label {
    font-size: 1.5rem;
}

.mt--3 {
    margin-top: -2rem;
}

.wizard-card-large .form-check:has(input[type="radio"]:checked),
.wizard-card-large.w-mobile .form-check:has(input[type="radio"]:checked) {
    box-shadow: 0px 0px 0px 2px rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) inset;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
    background: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
    /*box-shadow: 0px 0px 0px 2px rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) inset;
    color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));*/
}

/*.wizard-card-large .form-check input[type="radio"]:checked+label {
    color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}*/

.min-height-210 {
    min-height: 210px;
}

.min-height-150 {
    min-height: 150px;
}

p.divider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--bs-light-text);
    line-height: 0.1em;
    margin: 10px 0 20px;
}

p.divider span {
    background: var(--bs-white);
    padding: 0 10px;
}

.ego-min-height-card-image {
    min-height: 50px;
}

/* dividerStyles.css */
.horizontal-divider {
    border-top: 1px solid var(--bs-light-text);
    margin: 0.5rem 0;
    width: 100%;
}

.white-hr {
    border-top: 2px solid var(--bs-white);
}

.column-count-2 {
    column-count: 2;
}

textarea.no-resize {
    resize: none;
}

.ego-datepicker {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

.vertical-rule-white:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 75%;
    right: 0;
    top: 50%; /* Move the top edge to the center of the container */
    transform: translateY(-50%); /* Shift it up by half its own height */
    background-color: var(--bs-white);
}

.vertical-rule-grey:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: var(--bs-border-color-translucent);
}

.vertical-rule-grey-75:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 75%;
    right: 0;
    bottom: 0;
    background-color: var(--bs-border-color-translucent);
}

.input-with-euro-amount input {
    padding-right: 2rem;
}

.input-with-day-amount input {
    padding-right: 8.25rem;
}

.input-with-euro-amount span,
.input-with-day-amount span{
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.4rem 1rem 0.4rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.progress,
.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: var(--bs-body-color);
    --bs-progress-bar-bg: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    overflow: hidden;
    letter-spacing: 0.1rem;
    color: rgba(var(--bs-dark), var(--bs-bg-opacity));
    text-align: center;
    white-space: nowrap;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
    transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.nowrap {
    white-space: nowrap;
}

.download-doc {
    text-decoration: none;
    transition: all .3s;
}

.download-doc > i {
    width: 23px;
    height: 20px;
    display: table;
    float: left;
    text-align: center;
    font-size: 0.75rem;
    line-height: 20px;
    border-radius: 23px;
    margin-right: 0.5rem;
    transition: all .3s;
    border: 2px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.download-doc.download-doc-white-style > i {
    border: 2px solid rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.download-doc > strong {
    font-family: Jost,sans-serif;
    transition: all .3s;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.download-doc > span {
    transition: all .3s;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.download-doc.download-doc-white-style > strong {
    color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.download-doc.download-doc-white-style > span {
    color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.download-doc:hover > i {
    border: 2px solid transparent;
}

.download-doc:hover > strong, .download-doc:hover > span {
    color: var(--bs-danger);
}

.single-price-block-long-name-font {
    font-size: 0.875rem;
}

@media all and (min-width: 768px) and (max-width: 992px) {
    .single-price-block-long-name-font {
        font-size: 1.1rem;
    }
}

@media all and (min-width: 1200px) and (max-width: 1400px) {
    .single-price-block-long-name-font {
        font-size: 1.1rem;
    }
}

.mw-50 {
    max-width: 50% !important;
}

.mb-7px {
    margin-bottom: 7px;
}

.color-picker .form-check {
    display: table;
    float: left;
}

.color-picker .form-check input {
    display: none;
}

.color-picker .form-check label {
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: transparent;
    text-indent: -9999px;
    cursor: pointer;
    border: 1px solid rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.color-picker .form-check label:hover {
    border: 1px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.color-picker .form-check input[type="radio"]:checked+label {
    border: 2px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    position: relative;
}

.color-picker .form-check input[type="radio"]:checked+label:before {
    content: "\f26e";
    font-family: bootstrap-icons!important;
    position: absolute;
    top: 0;
    text-indent: 0;
    left: 50%;
    margin-left: -10px;
    color: var(--bs-dark);
    font-size: 1.3rem;
    z-index: 10;
}

.filter-panel .filter-panel-color input.btn-check + .btn {
    position: relative;
    border: 0;
    padding-top: 2.5rem;
    background: transparent;
    color: var(--bs-info);
}

.filter-panel .filter-panel-color input.btn-check:checked + .btn {
    color: var(--bs-dark);
}

.filter-panel .filter-panel-color input.btn-check + .btn:after {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    margin-left: -1rem;
    width: 2rem !important;
    height: 2rem;
    border-radius: 2rem;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid var(--bs-info);
}

.filter-panel .filter-panel-color input.btn-check + .btn:hover:after {
    border: 1px solid var(--bs-dark);
}

.filter-panel .filter-panel-color input.btn-check:checked + .btn:after {
    border: 2px solid var(--bs-dark);
}

.filter-panel .filter-panel-color input.btn-check:checked + .btn:before {
    content: "\f26e";
    font-family: bootstrap-icons!important;
    position: absolute;
    top: 2px;
    left: 50%;
    margin-left: -10px;
    color: var(--bs-dark);
    font-size: 1.3rem;
    z-index: 10;
}

.filter-panel .filter-panel-color input[value="black"].btn-check:checked + .btn:before,
.filter-panel .filter-panel-color input[value="blue"].btn-check:checked + .btn:before,
.filter-panel .filter-panel-color input[value="grey"].btn-check:checked + .btn:before,
.filter-panel .filter-panel-color input[value="red"].btn-check:checked + .btn:before,
.color-picker .product-color-black.form-check label:before,
.color-picker .product-color-blue.form-check label:before,
.color-picker .product-color-grey.form-check label:before,
    .color-picker .product-color-red.form-check label:before {
        color: var(--bs-white) !important;
}

.color-picker .product-color-white.form-check label,
.filter-panel .filter-panel-color input[value="white"].btn-check + .btn:after {
    background-color: var(--bs-white);
}

.color-picker .product-color-blue.form-check label,
.filter-panel .filter-panel-color input[value="blue"].btn-check + .btn:after {
    background-color: var(--bs-primary);
}

.color-picker .product-color-yellow.form-check label,
.filter-panel .filter-panel-color input[value="yellow"].btn-check + .btn:after {
    background-color: #ffc956;
}

.color-picker .product-color-red.form-check label,
.filter-panel .filter-panel-color input[value="red"].btn-check + .btn:after {
    background-color: #ba0c2f;
}

.color-picker .product-color-orange.form-check label,
.filter-panel .filter-panel-color input[value="orange"].btn-check + .btn:after {
    background-color: var(--bs-danger);
}

.color-picker .product-color-black.form-check label,
.filter-panel .filter-panel-color input[value="black"].btn-check + .btn:after {
    background-color: var(--bs-dark);
}

.color-picker .product-color-grey.form-check label,
.filter-panel .filter-panel-color input[value="grey"].btn-check + .btn:after {
    background-color: var(--bs-info);
}

.color-picker .product-color-green.form-check label,
.filter-panel .filter-panel-color input[value="green"].btn-check + .btn:after {
    background-color: #d5ddce;
}

.color-picker .product-color-pink.form-check label,
.filter-panel .filter-panel-color input[value="pink"].btn-check + .btn:after {
    background-color: #edd4d7;
}