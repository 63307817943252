.btn-text {
    position: relative;
    box-shadow: 0px 0px 0px 2px var(--bs-dark) inset;
    border-radius: 30px !important;
    color: var(--bs-dark);
    width: auto;
    height: 45px;
    display: flex !important;
    margin: 0 0.4rem;
    justify-content: center;
    align-items: center;
    transition: all 0.3s !important;
}

.btn-text:disabled {
    opacity: 1 !important;
}

.btn-text.btn-md {
    height: 52px;
}

.btn-text.btn-lg {
    height: 60px;
}

.btn-text.inverse {
    color: #fff;
    box-shadow: 0px 0px 0px 2px #fff inset;
}

.btn-text:hover {
    box-shadow: none;
    color: #fff !important;
}

.btn-text:focus-visible {
    box-shadow: 0px 0px 0px 2px var(--bs-dark) inset !important;
}

.btn-text.inverse:focus-visible {
    box-shadow: 0px 0px 0px 2px #fff inset !important;
}

.btn-text .bg-btn {
    position: absolute;
    transition: all 0.3s;
    top: -1px;
    left: -1px;
    width: 102%;
    height: 105%;
    border-radius: 30px;
    transform: translate(5px, 5px);
    z-index: -1;
}

.btn-text:disabled .bg-btn {
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.btn-text:hover .bg-btn {
    transform: translate(-1px, -1px);
}

.btn-text .count-block {
    width: auto;
    height: 20px;
    font-size: 0.7rem;
    padding: 0 0.5rem;
    line-height: 20px;
    display: table;
    top: -9px;
    right: -5px;
    position: absolute;
    border-radius: 20px;
    box-shadow: 0px 0px 0px 2px var(--bs-dark) inset;
}

.btn-text:hover .count-block {
    box-shadow: 0px 0px 0px 1px var(--bs-dark) inset;
}