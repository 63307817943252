.show-more {
    display: table;
}

.show-more .icon {
    font-size: 0.5rem;
}

.show-more .iconfont-wrapper {
    align-items: center;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--bs-dark);
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 0 0.4rem;
    min-width: 20px;
    position: relative;
    transition: all .3s;
    width: 20px;
    font-size: 0.5rem;
}

.show-more.inverse .iconfont-wrapper {
    box-shadow: inset 0 0 0 2px #fff;
}

.show-more .iconfont-wrapper:hover {
    box-shadow: inset 0 0 0 2px transparent;
}

.show-more .icon-bg {
    border-radius: 4px;
    box-sizing: border-box;
    height: 100%;
    overflow: visible;
    position: absolute;
    right: -3px;
    top: 3px;
    transition: all .3s;
    width: 100%;
    z-index: -1;
}

.show-more .iconfont-wrapper:hover .icon-bg {
    right: 0;
    top: 0;
}

.show-more .iconfont-wrapper:hover .icon {
    color: #fff;
}

.show-more .link {
    font-family: Jost,sans-serif;
}

.show-more p a {
    transition: all .3s;
}

.show-more p a:hover {
    color: var(--bs-danger)!important;
}

.show-more .description {
    font-size: 15px;
}