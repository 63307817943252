.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media all and (max-width: 389.98px) {
  h1.logo-title {
    font-size: calc(1rem + 1.5vw);
    margin-top: 13px;
  }
}

@media all and (max-width: 767.98px) {
  h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.promo-header {
  padding: 5px 0;
}

.promo-banner-block {
  height: 400px;
  width: 100%;
  background: url("../src/styles/images/00. top-block/energy1.jpg") center no-repeat;
  background-size: 100% auto;
}

.promo-banner-block .promo-banner-block-text {
  width: 100%;
  max-width: 30rem;
  bottom: 3rem;
  color: #fff;
  left: 15%;
  position: absolute;
  right: 15%;
  text-align: center;
}

@media all and (max-width: 767.98px) {
  .logo-title {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .promo-banner-block {
    height: auto;
    background-size: cover;
  }

  .promo-banner-block .promo-banner-block-text {
    width: 90%;
    max-width: initial;
    bottom: initial;
    color: #fff;
    left: initial;
    margin: 0 1%;
    position: relative;
    right: initial;
    text-align: center;
  }
}

.promo-idea-block .promo-idea-block-lamp {
  position: absolute;
  display: table;
  width: 16%;
  height: auto;
  left: 50%;
  margin-left: -7%;
  top: 0;
  margin-top: -9%;
}

.promo-idea-block .promo-idea-block-main-image {
  width: 85%;
  display: table;
}

@media all and (min-width: 768px) and (max-width: 1199.98px) {
  .promo-idea-block .promo-idea-block-main-image {
    width: 100%;
  }
}

@media all and (max-width: 767.98px) {
  .promo-idea-block .promo-idea-block-main-image {
    width: 100%;
    padding-top: 5rem;
  }
}

.promo-idea-block .promo-idea-block-info {
  width: 40%;
  position: absolute;
  left: 50%;
  margin-left: 10%;
  top: 0;
  padding: 1rem;
  border-radius: 0.375rem;
}

.promo-idea-block .promo-idea-block-info p {
  font-size: 1.25rem;
}

@media all and (min-width: 992px) and (max-width: 1199.98px) {
  .promo-idea-block .promo-idea-block-lamp {
    margin-top: -10%;
  }

  .promo-idea-block .promo-idea-block-info {
    padding: 0.5rem 1rem;
  }

  .promo-idea-block .promo-idea-block-info p {
    font-size: 1.25rem;
  }
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .promo-idea-block .promo-idea-block-lamp {
    margin-top: -12%;
  }

  .promo-idea-block .promo-idea-block-info {
    margin-top: -5%;
    padding: 0.5rem 1rem;
  }

  .promo-idea-block .promo-idea-block-info p {
    font-size: 0.95rem;
  }
}

@media all and (min-width: 576px) and (max-width: 767.98px) {
  .promo-idea-block .promo-idea-block-lamp {
    position: absolute;
    display: table;
    width: 22%;
    height: auto;
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: -18%;
  }

  .promo-idea-block .promo-idea-block-info {
    width: calc(1.5rem * 0.5 + 78%);
    position: absolute;
    left: initial;
    margin-left: 0;
    top: 0;
    right: 0;
    padding: 1rem;
    margin-top: -80px;
    margin-right: calc(-1.5rem * 0.5);
    border-radius: 0.375rem;
  }

  .promo-idea-block .promo-idea-block-info p {
    font-size: 1rem;
  }
}

@media all and (min-width: 400px) and (max-width: 575.98px) {
  .promo-idea-block .promo-idea-block-lamp {
    position: absolute;
    display: table;
    width: 25%;
    height: auto;
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: -6rem;
  }

  .promo-idea-block .promo-idea-block-info {
    width: calc(1.5rem * 0.5 + 75%);
    position: absolute;
    left: initial;
    margin-left: 0;
    top: 0;
    right: 0;
    padding: 1rem;
    margin-top: -75px;
    margin-right: calc(-1.5rem * 0.5);
    border-radius: 0.375rem 0 0 0.375rem;
  }

  .promo-idea-block .promo-idea-block-main-image {
    padding-top: 7.5rem;
  }

  .promo-idea-block .promo-idea-block-info p {
    font-size: 0.75rem;
  }
}

@media all and (max-width: 399.98px) {
  .promo-idea-block .promo-idea-block-lamp {
    position: absolute;
    display: table;
    width: 25%;
    height: auto;
    left: 0;
    margin-left: 0;
    top: 0;
    margin-top: -5.5rem;
  }

  .promo-idea-block .promo-idea-block-info {
    width: calc(1.5rem * 0.5 + 75%);
    position: absolute;
    left: initial;
    margin-left: 0;
    top: 0;
    right: 0;
    padding: 1rem;
    margin-top: -85px;
    margin-right: calc(-1.5rem * 0.5);
    border-radius: 0.375rem 0 0 0.375rem;
  }

  .promo-idea-block .promo-idea-block-info p {
    font-size: 0.75rem;
  }

  .promo-idea-block .promo-idea-block-main-image {
    padding-top: 7rem;
  }
}

.promo-marketplace-block .promo-marketplace-image {
  width: 25%;
  display: table;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -15%;
  z-index: 1;
}

.promo-marketplace-block .promo-marketplace-image.right-pos {
  left: auto;
  right: 0;
  margin-bottom: -12%;
}

.promo-data-table {
  display: table;
  position: relative;
  padding-bottom: 8px;
}

.promo-data-table:after {
  content: "";
  position: absolute;
  bottom: 1px;
  width: 9px;
  height: 9px;
  left: 50%;
  margin-left: -5px;
  background-color: rgb(144, 146, 148);
  border-radius: 20px;
}

.promo-data-table hr {
  position: absolute;
  width: 100%;
  height: 22px;
  border: 0;
  background-color: rgb(144, 146, 148);
  opacity: 1;
  top: 2.3rem;
  margin: 0;
  padding: 10px 0;
  background-clip: content-box;
}

.promo-data-table hr:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 20px;
  position: absolute;
  left: 0;
  background-color: rgb(144, 146, 148) !important;
  opacity: 1;
  top: 7px;
}

.promo-data-table hr:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  background-color: rgb(144, 146, 148) !important;
  opacity: 1;
  top: 7px;
}

.promo-data-table .promo-data-table-header, .promo-data-table .promo-data-table-row {
  display: flex;
}

.roadmap-block .promo-data-table .promo-data-table-header, .roadmap-block .promo-data-table .promo-data-table-row {
  position: relative;
}

.roadmap-block .promo-data-table .promo-data-table-header, .roadmap-block .promo-data-table .promo-data-table-row .promo-data-table-step-info {
  width: 25%;
  position: absolute;
  left: -25%;
  bottom: -30px;
}

@media all and (max-width: 767.98px) {
  .roadmap-block .promo-data-table .promo-data-table-header, .roadmap-block .promo-data-table .promo-data-table-row .promo-data-table-step-info {
    width: 30%;
    position: absolute;
    left: -15%;
    bottom: -35px;
    z-index: 1;
  }
}

.promo-data-table .promo-data-table-block {
  display: block;
  width: 50%;
}

.promo-data-table .promo-data-table-row .promo-data-table-block {
  padding-top: 2rem;
}

.promo-data-table .promo-data-table-block .promo-data-table-block-content {
  display: flex;
}

.promo-data-table .promo-data-table-block.left-block {
  padding-right: 1rem;
  text-align: right;
  border-right: 2px solid rgb(144, 146, 148);
  position: relative;
}

.promo-data-table .promo-data-table-row .promo-data-table-block.left-block:before {
  content: "";
  width: 1rem;
  height: 2px;
  position: absolute;
  background-color: rgb(144, 146, 148);
  right: 0;
  top: 4.5rem;
}

.promo-data-table .promo-data-table-row .promo-data-table-block.left-block.first-ch:before {
  top: 3.5rem;
}

.promo-data-table .promo-data-table-block.left-block.first-ch {
  padding-top: 1rem;
  margin-top: 1rem;
}

.promo-data-table .promo-data-table-block.right-block {
  text-align: left;
  padding-left: 1rem;
  position: relative;
}

.promo-data-table .promo-data-table-row .promo-data-table-block.right-block:before {
  content: "";
  width: 1rem;
  height: 2px;
  position: absolute;
  background-color: rgb(144, 146, 148);
  left: 0;
  top: 4.5rem;
}

.promo-data-table .promo-data-table-row .promo-data-table-block.right-block.first-ch:before {
  top: 3.5rem;
}

.promo-data-table .promo-data-table-block.right-block.first-ch {
  padding-top: 1rem;
  margin-top: 1rem;
}

.promo-data-table .promo-data-table-block.left-block .promo-data-table-block-content {
  justify-content: flex-end;
}

.promo-data-table .promo-data-table-row .promo-data-table-icon {
  width: 5rem;
  height: 5rem;
  display: block;
  border-radius: 5rem;
  position: relative;
  flex: 0 0 5rem;
}

.promo-data-table .promo-data-table-row .promo-data-table-icon img {
  position: absolute;
  left: 18%;
  top: 18%;
  width: 64%;
  height: 64%;
}

.promo-data-table.one-column-table {
  display: table;
  position: relative;
  padding-bottom: 8px;
  padding-top: 8px;
  /*padding-left: 4px;*/
  width: 100%;
  padding-left: 20%;
}

.roadmap-block .promo-data-table.one-column-table:before {
  content: "▼";
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 20%;
  bottom: -11px;
  margin-left: -8.5px;
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .roadmap-block .promo-data-table.one-column-table {
    width: 100%;
    padding-left: 20%;
  }
}

@media all and (max-width: 767.98px) {
  .promo-data-table.one-column-table {
    padding-left: 10%;
  }

  .roadmap-block .promo-data-table.one-column-table:before {
    content: "▼";
    color: #fff;
    font-size: 18px;
    position: absolute;
    left: 10%;
    bottom: -11px;
    margin-left: -8.5px;
  }
}

.roadmap-block .promo-data-table.one-column-table:after {
  background-color: #fff;
  left: calc(20% + 1px);
  top: 0;
}

@media all and (max-width: 767.98px) {
  .roadmap-block .promo-data-table.one-column-table:after {
    left: calc(10% + 1px);
  }
}

.promo-data-table.one-column-table .promo-data-table-block {
  width: 100%;
}

.promo-data-table.one-column-table .promo-data-table-row .promo-data-table-icon img {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.roadmap-block .promo-data-table.one-column-table .promo-data-table-row .promo-data-table-icon {
  width: 9rem;
  height: 9rem;
  flex: 0 0 9rem;
}

@media all and (max-width: 767.98px) {
  .roadmap-block .promo-data-table.one-column-table .promo-data-table-row .promo-data-table-icon {
    width: 5rem;
    height: 5rem;
    flex: 0 0 5rem;
  }
}

.promo-data-table.one-column-table .promo-data-table-block .promo-data-table-block-content {
  align-items: center;
}

.promo-data-table.one-column-table .promo-data-table-block.right-block {
  padding-left: 2rem;
}

.promo-data-table.one-column-table .promo-data-table-block.right-block {
  border-left: 2px solid #fff;
}

.promo-data-table.one-column-table .promo-data-table-row .promo-data-table-block.right-block:before {
  content: "";
  width: 2rem;
  height: 2px;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 5.5rem;
}

@media all and (max-width: 767.98px) {
  .promo-data-table.one-column-table .promo-data-table-row .promo-data-table-block.right-block:before {
    top: calc(50% + 1rem);
    width: 1rem;
  }

  .roadmap-block .promo-data-table-text-block p {
    font-size: 0.95rem;
  }

  .roadmap-block .ego-text-subtitle {
    font-size: calc(1.15rem + 0.6vw) !important;
  }

  .promo-data-table.one-column-table .promo-data-table-block.right-block {
    padding-left: 1rem;
  }
}

.main-comp-block .main-comp-block-text {
  width: 60%;
}

@media all and (min-width: 576px) and (max-width: 767.98px) {
  .main-comp-block .main-comp-block-text {
    width: 50%;
  }

  .main-comp-block .main-comp-block-text .ego-text-description {
    font-size: 1rem;
  }

  .roadmap-image-promo {
    padding-top: 5rem;
  }
}

.main-comp-block img {
  margin-top: 4.5rem;
}

@media all and (max-width: 575.98px) {
  .main-comp-block img {
    margin-top: 2rem;
    padding-top: 0;
  }

  .main-comp-block .main-comp-block-text {
    width: 100%;
  }

  .main-comp-block .main-comp-block-text .ego-text-description {
    font-size: 1rem;
  }

  .roadmap-image-promo {
    padding-top: 5rem;
  }
}

.promo-footer-link {
  text-decoration: underline;
  cursor: pointer;
}

.promo-footer-link:hover, .promo-footer-link:active, .promo-footer-link:focus {
  text-decoration: none;
}

@media all and (min-width: 992px) and (max-width: 1199.98px) {
  .main-comp-block .main-comp-block-text {
    width: 70%;
  }

  .main-comp-block img {
    margin-top: 5rem;
  }
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .main-comp-block .main-comp-block-text {
    width: 70%;
  }

  .main-comp-block .main-comp-block-text .ego-text-description {
    font-size: 0.95rem;
  }

  .main-comp-block img {
    margin-top: 4.5rem;
  }
}

@media all and (max-width: 767.98px) {
  .roadmap-block .promo-data-table-text-block p,
  .main-comp-block .main-comp-block-text .ego-text-description,
  .promo-idea-block .promo-idea-block-info p,
  .promo-page-v1 .ego-text-description {
    font-size: 0.95rem !important;
  }

  .roadmap-block .ego-text-subtitle, .promo-page-v1 .ego-text-name {
    font-size: calc(1.15rem + 0.6vw) !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

